import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../components/errorMessage/ErrorMessage';
import DatePicker from '../components/datePicker/DatePicker';
import TimePicker from '../components/timePicker/TimePicker';
import { formatDate, formatDateTime, formatTimeWithoutZone } from '../utils/formatDateTime';
import LoadingOverlay from '../components/loadingOverlay/LoadingOverlay';
import { addMinutes, parseISO } from 'date-fns';
import { updateBookingTimes } from '../services/apiService';
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';

const ChangeTimeBookingPage: React.FC = () => {
  // Access currentBooking directly from the state
  const { currentBooking } = useSelector((state: RootState) => state.bookingList);
  const [date, setDate] = useState<string | null>(currentBooking?.startTime ? formatDate(new Date (currentBooking?.startTime)) : null || null);
  const [formattedDate, setFormattedDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>( currentBooking?.startTime ?  formatTimeWithoutZone(currentBooking?.startTime) : null);
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  const [errorKey, setErrorKey] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  
  useEffect(() => {
    // Ensure this useEffect is always called
  
    if (currentBooking) {
      if (time && date) {
        const formattedDateTime = formatDateTime(`${date}T${time}`);
        setFormattedDate(formattedDateTime);
      }
    }
  }, [currentBooking]);



  if (!currentBooking) {
    return <div>Loading...</div>;
  }

  const eventDetails:EventDetails = {
    eventName: 'MONDIAL AUTO',
    venueId: 1
  };

  const handleValidateSlot = async () => {
    const errors = validateSelections();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setErrorKey((prevKey) => prevKey + 1);
    } else if(formattedDate) {
      try {
        setLoading(true);
        setError('');
        const startTime = formattedDate;
        const endTime = addMinutes(parseISO(startTime), currentBooking.offer.duration);
        const formattedEndDate = formatDateTime(`${endTime}`);
        const bookingData = {
          startTime,
          endTime: formattedEndDate
        }

        await updateBookingTimes(currentBooking.id, bookingData);

        navigate(`/booking-details/${currentBooking.id}`); 

      } catch (error) {
       // const messageError = bookedError(error);
        setError('Modification du créneau error');
      } finally {
        setLoading(false);
      }
    }
  };

  const validateSelections = (): Record<string, string> => {
    const errors: Record<string, string> = {};
    if (!date) errors.date = 'Veuillez sélectionner une date';
    if (!time) errors.time = 'Veuillez sélectionner une heure';
    return errors;
  };

  const handleDateChange = (date: string) => {
    setDate(date);
    if (time) {
      const formattedDateTime = formatDateTime(`${date}T${time}`);
      setFormattedDate(formattedDateTime);
    }
    setErrors((prevErrors) => ({ ...prevErrors, date: null }));
  };

  const handleTimeChange = (time: string) => {
    setTime(time);
    if (date) {
      const formattedDateTime = formatDateTime(`${date}T${time}`);
      setFormattedDate(formattedDateTime);
    }
    setErrors((prevErrors) => ({ ...prevErrors, time: null }));
  };


  return (
    <div>
      <div className="flex justify-between items-center mb-10">
        <h1 className="text-2xl md:text-3xl font-bold">Modification du créneau</h1>
        <Link to={`/booking-details/${currentBooking.id}`} className="text-gold hover:text-black flex items-center gap-x-1">
          <ArrowLongLeftIcon className="h-4 w-4" /> Retour
        </Link>
      </div>
      {/* Pass currentBooking.customers to CustomerFormsList */}

      <div className="w-full">
        <div className="md:flex">
          <div className="md:w-3/5 md:pr-10">
            <ErrorMessage message={errors.date} errorKey={errorKey} />
            <DatePicker onDateChange={handleDateChange} selectedDate={date} />
          </div>
          <div className="md:w-2/5 md:mt-0 mt-8">
            <ErrorMessage message={errors.time} errorKey={errorKey} />
            <TimePicker
              onTimeChange={handleTimeChange}
              selectedTime={time}
              selectedDate={date}
              offerId={currentBooking.offer.id || 0}
              groupSize={currentBooking.groupSize}
              venueId={eventDetails.venueId}
            />
          </div>
        </div>
        <div className="flex justify-end mt-5">
          <button
            onClick={handleValidateSlot}
            className="mt-4 bg-gold text-black py-2 px-4 rounded-md hover:bg-black hover:text-gold"
          >
           Valider
          </button>
        </div>
        {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
        <LoadingOverlay isLoading={loading} />
      </div>
      {/* Add buttons or actions for saving the participants */}
    </div>
  );
};

export default ChangeTimeBookingPage;