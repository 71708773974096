import React from 'react';
import { useSelector } from 'react-redux';
import { isValidBooking } from '../../utils/cart';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../store'; // Assuming you have RootState defined in your store
import { BookingState, CartItem } from '../../store/stateModel/stateModel';

const Summary: React.FC = () => {
  const location = useLocation();
  
  const bookingDetails = useSelector((state: RootState) => state.booking);
  const cartItems = useSelector((state: RootState) => state.cart.items);

  // Determine if we're on the checkout page
  const isCheckout = location.pathname === '/checkout';

  // Prepare items to display in the summary
  const itemsToDisplay: (CartItem | BookingState)[] = isCheckout
    ? cartItems
    : [...cartItems, ...(isValidBooking(bookingDetails) ? [bookingDetails] : [])].filter(
        (item) => item && item.selectedOffer
      );

  // Calculate the grand total
  const grandTotal = itemsToDisplay.reduce((total, item) => {
    const finalTotal = item.finalTotal ?? item.selectedOffer.price * item.groupSize;
    return total + finalTotal;
  }, 0);

  return (
    <div id="summary" className="bg-gray-50 p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Récapitulatif réservation</h2>
      {itemsToDisplay.map((item, index) => {
        const originalTotal = item.selectedOffer.price * item.groupSize;
        
        return (
          <div key={index} className="mb-4">
            <div className="flex justify-between mb-2">
              <span className="text-gray-700">Session:</span>
              <span className="text-gray-900">{item.selectedOffer.name}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="text-gray-700">Nombre de pilotes:</span>
              <span className="text-gray-900">{item.groupSize}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="text-gray-700">Total:</span>
              <span
                className={`text-gray-500 ${
                  item.finalTotal !== originalTotal ? 'line-through text-red-500' : 'text-green-700'
                }`}
              >
                {originalTotal.toFixed(2)}€
              </span>
            </div>
            {item.finalTotal !== originalTotal && (
              <div className="flex justify-between mb-2">
                <span className="text-gray-700">Total remisé:</span>
                <span className="text-green-700">{item.finalTotal?.toFixed(2)}€</span>
              </div>
            )}
            {index < itemsToDisplay.length - 1 && <hr className="border-t border-gray-300 my-4" />}
          </div>
        );
      })}

      {/* Grand Total */}
      <div className="mt-6 border-t pt-4">
        <div className="flex justify-between">
          <span className="text-md font-bold text-gray-700">Total général:</span>
          <span className="text-md font-bold text-green-700">{grandTotal.toFixed(2)}€</span>
        </div>
      </div>
    </div>
  );
};

export default Summary;