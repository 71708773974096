import axios from 'axios';
import { logout, refreshAccessToken } from '../store/auth/authSlice';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Interceptor to check for 401 responses and allow external retry logic
export const setupInterceptors = (dispatch: any, getState: any) => {
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If 401 Unauthorized is returned, try to refresh the token
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // Attempt to refresh the token
          const refreshResponse = await dispatch(refreshAccessToken());

          if (refreshResponse.payload) {
            const newAccessToken = refreshResponse.payload as string;

            // Update the Authorization header and retry the original request
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return axios(originalRequest);  // Retry the original request
          } else {
            dispatch(logout());  // Logout if refresh failed
            return Promise.reject('Failed to refresh token, please log in again.');
          }
        } catch (err) {
          dispatch(logout());  // Log out if token refresh fails
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    }
  );
};

export default apiClient;