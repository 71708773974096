// src/components/CustomerFormsList/CustomerFormsList.tsx
import React, { useState } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import EmailChecker from '../emailChecker/EmailChecker'; // Make sure EmailChecker is refactored to TypeScript
import CustomerForm from './CustomerForm'; // Make sure CustomerForm is refactored to TypeScript

// Define types for props

interface CustomerFormsListProps {
  groupSize: number;
  customers: CustomerDto[];
  onCustomersChange: (customers: CustomerDto[]) => void;
  eventDetails: EventDetails;
  isFromParticipantsPage?: boolean;
  bookingId?: number;
}


const CustomerFormsList: React.FC<CustomerFormsListProps> = ({ groupSize, customers, onCustomersChange, eventDetails, isFromParticipantsPage = false, bookingId }) => {
 
  const [isCustomerChecked, setIsCustomerChecked] = useState(() =>
    customers.map((customer) => !!customer.email)
  );
  const [isCustomerFound, setIsCustomerFound] = useState(() =>
    customers.map((customer) => !!customer.id)
  );
  const [searchedEmails, setSearchedEmails] = useState(customers.map(customer => customer.email || null));
  const [errors, setErrors] = useState<{ [index: number]: { [field: string]: string } }>({});


  const handleEmailCheck = (index: number, customerData: CustomerDto | null, email: string = '') => {
    const updatedCustomers = [...customers];
    const updatedIsCustomerChecked = [...isCustomerChecked];
    const updatedIsCustomerFound = [...isCustomerFound];

    if ( customerData ) {
      updatedCustomers[index] = customerData;
    }

    updatedIsCustomerChecked[index] = true;
    updatedIsCustomerFound[index] = !!customerData;

    //setCustomers(updatedCustomers);
    setIsCustomerChecked(updatedIsCustomerChecked);
    setIsCustomerFound(updatedIsCustomerFound);


    if (customerData) {
      onCustomersChange(updatedCustomers); 
    }

    const updatedEmails = [...searchedEmails];
    updatedEmails[index] =  email || customerData?.email || null
    setSearchedEmails(updatedEmails);
  };

  const handleCustomerCreated = (index: number, customerData: CustomerDto) => {
    const updatedCustomers = [...customers];
    updatedCustomers[index] = customerData;
    onCustomersChange(updatedCustomers);
  };

  const handleCustomerChange = (index: number, field: keyof CustomerDto, value: string) => {
    const updatedCustomers = [...customers];
    updatedCustomers[index] = {
      ...updatedCustomers[index],
      [field]: value,
    };

    validateUniqueValues(index, field, value);
    onCustomersChange(updatedCustomers);
  };

  const validateUniqueValues = (index: number, field: keyof CustomerDto, value: string) => {
    let duplicateError = '';
    customers.forEach((customer, i) => {
      if (i !== index && customer[field] === value) {
        duplicateError = `Ce ${field} est déjà utilisé pour un autre participant`;
      }
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [field]: duplicateError,
      },
    }));
  };

  return (
    <div className="w-full">
      {Array.from({ length: groupSize }).map((_, index) => (
        <Disclosure as="div" key={index} defaultOpen={index === 0}>
          {({ open }) => (
            <>
              <DisclosureButton className="mb-2 flex w-full justify-between rounded-lg bg-gold px-4 py-4 text-left text-sm font-medium text-black hover:bg-black hover:text-gold focus:outline-none focus-visible:ring focus-visible:ring-gold focus-visible:ring-opacity-75">
                <span>{`Participant ${index + 1}`} {index === 0 && !isFromParticipantsPage ? "(obligatoire)" : ""}</span>
                <ChevronUpIcon className={`${open ? 'transform rotate-180' : ''} w-5 h-5`} />
              </DisclosureButton>
              <DisclosurePanel className="md:px-4 px-2 pt-4 pb-4 text-sm">
                {!isCustomerChecked[index] && (
                  <EmailChecker
                    onCustomerFound={(customerData) => handleEmailCheck(index, customerData)}
                    onCustomerNotFound={(email) => handleEmailCheck(index, null, email)}
                    searchedEmails={searchedEmails.filter((email): email is string => email !== null)}
                  />
                )}

              {!isFromParticipantsPage ? (
                <>

                {isCustomerChecked[index] && isCustomerFound[index] && customers.length > 0 && (
                  <div className="p-4 border-2 border-gold rounded-md">
                    <p><strong>Nom:</strong> {customers[index].name}</p>
                    <p><strong>Email:</strong> {customers[index].email}</p>
                  </div>
                )}
                {isCustomerChecked[index] && !isCustomerFound[index] && (
                  <CustomerForm
                    onCustomerChange={(field, value) => handleCustomerChange(index, field, value)}
                    onCustomerCreated={(customerData) => handleCustomerCreated(index, customerData)}
                    eventDetails={eventDetails}
                    customerExist={customers[index]}
                    externalErrors={errors[index]}
                    searchedEmail={searchedEmails[index] || ''}
                    iterationIndex={index}
                    required={index === 0}
                  />
                )}
                </>
              ) : (
                <>
                  {isCustomerChecked[index]  && (
                    <CustomerForm
                      onCustomerChange={(field, value) => handleCustomerChange(index, field, value)}
                      onCustomerCreated={(customerData) => handleCustomerCreated(index, customerData)}
                      eventDetails={eventDetails}
                      customerExist={customers[index]}
                      externalErrors={errors[index]}
                      searchedEmail={searchedEmails[index] || ''}
                      iterationIndex={index}
                      required={index === 0}
                      isFromParticipantsPage={isFromParticipantsPage} 
                      bookingId= {bookingId}
                    />
                  )}
                </>
              )}


              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default CustomerFormsList;