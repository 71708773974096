import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem } from '../stateModel/stateModel';

// Define types for the purchase state

interface PurchaseState {
  cartItems: CartItem[];
  finalTotal: number;
  paymentIntentId?: string | null;
  paymentStatus: 'pending' | 'success' | 'failed' | null;
  provider?: string | null;
}

const initialState: PurchaseState = {
  cartItems: [],
  finalTotal: 0,
  paymentIntentId: null,
  paymentStatus: null,
  provider: null
};

const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    storePurchaseDetails: (
      state,
      action: PayloadAction<{ cartItems: CartItem[]; finalTotal: number; paymentIntentId?: string, provider?: string }>
    ) => {
      state.cartItems = action.payload.cartItems;
      state.finalTotal = action.payload.finalTotal;
      state.paymentIntentId = action.payload.paymentIntentId;
      state.provider = action.payload.provider || null;
    },
    setPaymentStatus: (state, action: PayloadAction<'pending' | 'success' | 'failed'>) => {
      state.paymentStatus = action.payload;
    },
    clearPurchase: (state) => {
      state.cartItems = [];
      state.finalTotal = 0;
      state.paymentIntentId = null;
      state.paymentStatus = null;
      state.provider = null;
    },
  },
});

export const { storePurchaseDetails, setPaymentStatus, clearPurchase } = purchaseSlice.actions;

export default purchaseSlice.reducer;