// cart.ts

import { Offer } from "../models/offer";



interface Booking {
  groupSize: number;
  selectedOffer: Offer;
  finalTotal: number;
}

// Calculate the final total with group and children discounts
export const calculateFinalTotal = (
  selectedOffer: Offer,
  groupSize: number,
  childrenSize: number
): { finalTotal: number; originalTotal: number } => {
  const originalTotal = selectedOffer.price * groupSize;
  const isGroupDiscountApplicable = groupSize >= 4;
  const groupDiscountedTotal = isGroupDiscountApplicable ? parseFloat((originalTotal * 0.85).toFixed(2)) : originalTotal;
  const is_kid_friendly = selectedOffer.is_kid_friendly;

  let finalTotal = originalTotal;

  if (is_kid_friendly) {
    finalTotal = parseInt((originalTotal * 0.85).toFixed(2), 10);
  } else {
    if (isGroupDiscountApplicable) {
      finalTotal = groupDiscountedTotal;
    } else if (childrenSize) {
      const nonChildrenCount = groupSize - childrenSize;
      const nonDiscountedAmount = selectedOffer.price * nonChildrenCount;
      const childrenDiscountedAmount = selectedOffer.price * childrenSize * 0.85;
      finalTotal = nonDiscountedAmount + childrenDiscountedAmount;
    }
  }

  return { finalTotal, originalTotal };
};

// Validate the booking details
export const isValidBooking = (booking: Booking): boolean => {
  return booking.groupSize > 0 && !!booking.selectedOffer.name && booking.finalTotal > 0;
};