// formatDateTime.ts

export const formatDateTime = (dateTimeString: string): string => {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = '00'; // Setting seconds to '00'

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};


export const formatTimeWithoutZone = (time: string): string => {
  console.log('time', time);

  let hours: string;
  let minutes: string;

  const date = new Date(time);

  if (time.includes('Z')) {
     hours = date.getUTCHours().toString().padStart(2, '0');
     minutes = date.getUTCMinutes().toString().padStart(2, '0');
  } else {
     hours = date.getHours().toString().padStart(2, '0');
     minutes = date.getMinutes().toString().padStart(2, '0');
  }


  return `${hours}:${minutes}`;

}