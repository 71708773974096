import React, { useState } from 'react';
import './LoginForm.module.css'; 


interface LoginFormProps {
  onSubmit: (email: string, password: string) => void;
  errorMessage?: string | null;  // New prop for passing error messages
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, errorMessage }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Basic validation
    if (!email || !password) {
      setError('Les deux champs sont obligatoires.');
      return;
    }

    if (!emailRegex.test(email)) {
      setError('Veuillez entrer une adresse e-mail valide.');
      return;
    }
    
    // Clear error and submit form
    setError('');
    onSubmit(email, password);  // This will trigger the parent handler
  };

  return (
    <div className="w-full md:w-1/2 bg-white bg-opacity-10 backdrop-blur-md flex items-center justify-center p-8">
      <div className="max-w-sm w-full">
        
        <div className="mb-6 flex justify-center">
          <img src="/images/emotion-logo.png" alt="Logo" className="h-12" />
        </div>

        <h2 className="text-3xl font-extrabold text-center text-gold mb-6 tracking-wide uppercase">Connexion</h2>

        
        {error && <div className="text-red-500 mb-4">{error}</div>}

        {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}

        <form onSubmit={handleSubmit} noValidate>
          <div className="mb-4">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`w-full px-4 py-2 bg-transparent border text-white placeholder-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gold focus:border-transparent transition-all duration-300 ${error && !emailRegex.test(email) ? 'border-red-500' : 'border-gray-400'}`}
              placeholder="Enter your email"
            />
          </div>

        

          <div className="mb-6">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full px-4 py-2 bg-transparent border text-white placeholder-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gold focus:border-transparent transition-all duration-300  ${error && !password ? 'border-red-500' : 'border-gray-400'}`}
              placeholder="Mot de passe"
            />

          </div>

          <div className="flex justify-between items-center mb-6">
            <label className="inline-flex items-center">
              <input type="checkbox" className="form-checkbox text-gold rounded" />
              <span className="ml-2 text-sm text-white">Se souvenir de moi</span>
            </label>
            {/**
            <a href="#" className="text-sm text-gray-500 hover:underline hover:text-gold transition-colors duration-200">Mot de passe oublié ?</a>
             */}
          </div>

          <button className="w-full bg-gold text-white py-2 rounded-lg hover:bg-opacity-90 focus:outline-none transition-transform transform hover:scale-105 active:scale-100 shadow-lg">
            Connexion
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;