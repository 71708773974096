import React, { useEffect } from 'react';
import PaymentForm from '../components/payment/PaymentForm';
import Summary from '../components/summary/Summary';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store';  // Assuming you have RootState defined
import { resetBooking } from '../store/booking/bookingSlice';
import { selectCartFinalTotal } from '../store/cart/cartSlice';
import ProgressBar from '../components/progressBar/ProgressBar';

const CheckoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const unbookedItems = cartItems.filter((item) => !item.isBooked);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetBooking());
    if (unbookedItems.length > 0) {
      navigate('/cart'); // Redirect back to the cart
    }
  }, [dispatch, unbookedItems, navigate]);

  const finalTotal = useSelector(selectCartFinalTotal);

  return (
    <div className="checkout-page">
      <h1 className="text-2xl font-bold mb-10 text-center">Réservation Sessions Mondial de l'Auto Paris</h1>
      <ProgressBar currentStep={3} />
      <div className="md:flex w-full">
        <div className="md:w-3/5 md:px-5">
          <PaymentForm finalTotal={finalTotal} />
        </div>
        <div className="md:w-2/5 md:mt-0 mt-8 md:px-4">
          <Summary />
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;