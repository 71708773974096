// src/components/EmailChecker/EmailChecker.tsx
import React, { useState } from 'react';
import { checkCustomer } from '../../services/apiService';
import LoadingOverlay from '../loadingOverlay/LoadingOverlay';


interface EmailCheckerProps {
  onCustomerFound: (customer: CustomerDto | null) => void;
  onCustomerNotFound: (email: string) => void;
  searchedEmails: string[];
}

const EmailChecker: React.FC<EmailCheckerProps> = ({ onCustomerFound, onCustomerNotFound, searchedEmails }) => {
  const [email, setEmail] = useState<string>('');
  const [customer, setCustomer] = useState<CustomerDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleCheckEmail = async () => {
    if (!email) {
      setError('Email est obligatoire');
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setError('Email invalide');
      return;
    }

    const isDuplicate = searchedEmails.some((existingEmail) => existingEmail === email);
    if (isDuplicate) {
      setError('Email est déjà utilisé pour un autre participant');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const result = await checkCustomer(email);
      if (result) {
        const customerData = result;
        const customer: CustomerDto = {
          id: customerData.id,
          name: `${customerData.firstname} ${customerData.lastname}`,
          firstname: customerData.firstname,
          lastname: customerData.lastname,
          tel: customerData.tel,
          email: customerData.email,
          home_venue_id: customerData.homeVenueId,
          civilite: customerData.civilite,
          birthday: customerData.birthday
        };
        onCustomerFound(customer);
        setCustomer(customer);
      } else {
        onCustomerNotFound(email);
      }
    } catch (err) {
      setError('');
      onCustomerFound(null);
      onCustomerNotFound(email);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Email
      </label>
      <input
        type="email"
        id="email"
        name="email"
        className="mt-1 block w-full shadow border pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gold focus:border-gold sm:text-sm rounded-md"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button
        className="mt-4 w-full bg-gold text-black py-2 px-4 rounded-md hover:bg-black hover:text-gold"
        onClick={handleCheckEmail}
        disabled={loading}
      >
        {loading ? 'Vérification...' : 'Vérifier l\'email'}
      </button>
      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
      {customer && (
        <div className="mt-4">
          <h3 className="text-lg font-medium">Détails du client</h3>
          <p>Nom: {customer.firstname} {customer.lastname}</p>
          <p>Email: {customer.email}</p>
        </div>
      )}
      <LoadingOverlay isLoading={loading} />
    </div>
  );
};

export default EmailChecker;