import React from 'react';
import CheckoutForm from './CheckoutForm';

interface PaymentFormProps {
  finalTotal: number;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ finalTotal }) => {
  return (
    <CheckoutForm finalTotal={finalTotal} />
  );
};

export default PaymentForm;