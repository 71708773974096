// src/pages/CheckoutFailedPage.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { updatePaymentStatus } from '../services/apiService';


const CheckoutFailedPage: React.FC = () => {

  useEffect(() => {
    const paymentDetails = localStorage.getItem('sylqPayment');
  
    if (paymentDetails) {
      const parsedDetails = JSON.parse(paymentDetails);
      const { paymentId } = parsedDetails;
  
      const updateStatusAndCleanup = async () => {
        try {
          // Attempt to update the payment status
          await updatePaymentStatus(paymentId, 'failed');
        } catch (statusError: any) {
          console.error('Failed to update payment status:', statusError.message);
        } finally {
          // Clean up the localStorage after the update attempt
          localStorage.removeItem('sylqPayment');
        }
      };
  
      updateStatusAndCleanup();
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-red-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md">
        <ExclamationCircleIcon className="h-16 w-16 text-red-600 mx-auto mb-4" />
        <h1 className="text-3xl font-bold text-red-600 mb-4">Paiement Échoué</h1>
        <p className="text-gray-700 mb-6">
          Malheureusement, votre paiement n'a pas pu être traité. Veuillez réessayer ou contacter le support si le problème persiste.
        </p>
        <Link to="/checkout" className="bg-red-600 text-white py-2 px-6 rounded-md hover:bg-red-700">
          Réessayer le Paiement
        </Link>
        <p className="mt-4 text-gray-500">
          Besoin d'aide ? <Link to="/contact" className="text-red-600 hover:underline">Contactez-nous</Link>
        </p>
      </div>
    </div>
  );
};

export default CheckoutFailedPage;
