import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CartItem, CartState } from '../stateModel/stateModel';
import { Booking } from '../../models/booking';

// Define types for cart items and state


const initialState: CartState = {
  items: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart: (state, action: PayloadAction<CartItem>) => {
      state.items.push(action.payload);
    },
    removeItemFromCart: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item, index) => index !== action.payload);
    },
    clearCart: (state) => {
      state.items = [];
    },
    updateCartItemAsBooked: (
      state,
      action: PayloadAction<{ index: number; bookingData?: Booking, error?: any }>
    ) => {
      const { index, bookingData, error } = action.payload;
      const item = state.items[index];
      if (item) {
        state.items[index].isBooked = !error;
        state.items[index].bookingData = bookingData ? bookingData : null;
        state.items[index].error = error || null;
      }
    },
  },
});

export const { addItemToCart, removeItemFromCart, clearCart, updateCartItemAsBooked } = cartSlice.actions;

export const selectCartFinalTotal = (state: RootState) => state.cart.items.reduce((total, item) => total + item.finalTotal, 0);
export const selectCartItemSize = (state: RootState) => state.cart.items.length;
export const selectBookingIds = (state: RootState) =>
      state.cart.items
    .map((item) => item.bookingData?.id)
    .filter((id): id is number => id !== undefined); 
export const selectCustomerId = (state: RootState) =>  state.cart.items[0]?.customers?.[0]?.id || null;


export default cartSlice.reducer;