// src/pages/BookingDetailsPage.tsx
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BookingDetails from "../components/booking/bookingDetails/BookingDetails";
import { fetchBookingDetails } from "../store/booking/bookingListSlice";
import { AppDispatch, RootState } from "../store";

const BookingDetailsPage: React.FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>(); // Get bookingId from URL params
  const dispatch: AppDispatch = useDispatch();
  const { currentBooking, loading, error } = useSelector((state: RootState) => state.bookingList);

  useEffect(() => {
    if (bookingId) {
      dispatch(fetchBookingDetails(bookingId));
    }
  }, [dispatch, bookingId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!currentBooking) {
    return <div>No booking details found</div>;
  }

  return (
    <>
      {currentBooking ? (
        <>
          <BookingDetails booking={currentBooking} bookingId={bookingId!} />
        </>
      ) : (
        <p>Loading booking details...</p>
      )}
    </>
  );
};

export default BookingDetailsPage;