import React from 'react';
import { EventProps } from 'react-big-calendar';
import { Booking } from '../../models/booking'; // Your custom Booking type
import moment from 'moment';


const CustomEventComponent: React.FC<EventProps<any>> = ({ event }) => {
  // Directly access the properties from event, which is the full Booking object
  const truncatedTitle = event.title.length > 20 ? `${event.title.slice(0, 20)}...` : event.title;
  const formattedTime = moment(event.start).format('hA');  // 3PM format

  return (
    <div className="flex justify-between items-center">
      {/* Left column: Event Title */}
      <span className="truncate">
        {truncatedTitle}
      </span>

      {/* Right column: Event Time */}
      <span className="text-sm text-gray-500">
        {formattedTime}
      </span>
    </div>
  );
};

export default CustomEventComponent;