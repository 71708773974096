


import React, { useState } from 'react';
import dayjs from 'dayjs'; // You can use dayjs for date handling
import { Booking } from '../../models/booking';
import CustomToolbar from './CustomToolbar';
import CustomEvent from './CustomEvent';
import { Calendar, dateFnsLocalizer, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import styles from './Calendar.module.css';
import CustomEventComponent from './CustomEvent';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { CalendarProps } from '../../models/calendarProps';
import './Calendar.css'; 
import 'moment/locale/fr';


const localizer = momentLocalizer(moment);
moment.locale('fr');


const MyCalendar: React.FC<CalendarProps> = ({ bookings, onSlotSelect, onEventSelect }) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  
  const events = bookings.map((booking) => ({
    id: booking.id,
    start: new Date(booking.startTime),
    end: new Date(booking.endTime),
    title: booking.offer.name,
    detail : booking
  }));

  /*
  const bookingss = [
    { eventName: 'Event 1', startTime: '2024-09-22T10:00:00', endTime: '2024-09-22T11:00:00' },
    { eventName: 'Event 2', startTime: '2024-09-22T12:00:00', endTime: '2024-09-22T13:00:00' },
    { eventName: 'Event 3', startTime: '2024-09-22T14:00:00', endTime: '2024-09-22T15:00:00' },
  ];

  const eventss = bookingss.map((booking) => ({
    start: new Date(booking.startTime),
    end: new Date(booking.endTime),
    title: booking.eventName,
  }));
*/
  const messages = {
    allDay: 'Toute la journée',
    previous: 'Précédent',
    next: 'Suivant',
    today: "Aujourd'hui",
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Heure',
    event: 'Événement',
    noEventsInRange: 'Aucun événement dans cette période.',
    showMore: (total: number) => `+ ${total} plus`, 
  };
  return (
    <div className={`p-6 ${styles.calendar}`}>
      <Calendar
        localizer={localizer}
        events={events}
        style={{ height: 750 }}
       // selectable  // Enables slot selection
        //onSelectSlot={onSlotSelect}  // Pass the onSlotSelect handler
        //onSelectEvent={(event) => onEventSelect(event as Booking)}  // Pass the onEventSelect handler
        components={{
          //toolbar: CustomToolbar, // Toolbar customization
          event: CustomEventComponent,  // Use the custom event renderer
        }}
        dayLayoutAlgorithm="no-overlap"
         messages={messages} 
      />
    </div>
  );
};

export default MyCalendar;