// src/components/BookingDetails/BookingDetails.tsx
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Booking } from "../../../models/booking";
import { formatTimeWithoutZone } from "../../../utils/formatDateTime";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";

interface BookingDetailsProps {
  booking: Booking;
  bookingId: string;
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ booking, bookingId }) => {
  const formatDate = (dateString: string) => new Date(dateString).toLocaleDateString("fr-FR");
  

  const navigate = useNavigate();


  const handleEditTime = () => {
    navigate(`/booking/${bookingId}/change-time`);
  };

  const handleAddParticipants = () => {
    navigate(`/booking/${bookingId}/participants`);

  };


  return (
    <div>
        <div className="flex justify-between items-center mb-10">
          <h2 className="text-xl font-bold mb-4">Détails de la Réservation</h2>
          <Link to={'/'} className="text-gold hover:text-black flex items-center gap-x-1">
          <ArrowLongLeftIcon className="h-4 w-4" /> Retour
        </Link>
        </div>
       <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-bold text-gray-700 mb-4">Informations du client</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <p className="font-semibold text-gray-600">Nom:</p>
            <p className="text-gray-700">{booking.customer.firstname}  {booking.customer.lastname}</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Email:</p>
            <p className="text-gray-700">{booking.customer.email}</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Téléphone:</p>
            <p className="text-gray-700">{booking.customer.tel}</p>
          </div>
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
      <h2 className="text-xl font-bold text-gray-700 mb-4">Informations de la réservation</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <p className="font-semibold text-gray-600">Date:</p>
            <p className="text-gray-700">{formatDate(booking.startTime)}</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Heure:</p>
            <p className="text-gray-700">  { formatTimeWithoutZone(booking.startTime) }</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Session:</p>
            <p className="text-gray-700"> {  booking.offer.name  }</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Durée:</p>
            <p className="text-gray-700">{booking.offer.duration } min</p>
          </div>
          <div>
            <p className="font-semibold text-gray-600">Nombre de pilotes:</p>
            <p className="text-gray-700"> {booking.groupSize} pilote(s)</p>
          </div>
        </div>
      </div>

      <div className="flex justify-start space-x-4">
      <button
          onClick={handleEditTime}
          className="bg-gold text-white px-6 py-2  rounded-lg hover:bg-black hover:text-gold"
        >
          Modifier créneau
        </button>

        <button
          onClick={handleAddParticipants}
          className="bg-gray-700 text-white px-6 py-2  rounded-lg hover:bg-black"
        >
          Participants
        </button>
      </div>
    </div>
  );
};

export default BookingDetails;