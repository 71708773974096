import logoImage from '../assets/emotion-logo.png';
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ShoppingCartIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';

import { useSelector, useDispatch } from 'react-redux';
import { selectCartItemSize }  from '../store/cart/cartSlice'
import { logout } from '../store/auth/authSlice';
import React from "react";

const HomePage: React.FC = () => {
  const cartItemCount = useSelector(selectCartItemSize);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout()); // Assuming the logout action clears user state and tokens
    navigate('/login'); // Redirect to the login page
  };



  return (
    <div className="bg-gray-100 min-h-screen flex flex-col bg-cover bg-center bg-gradient-to-b from-gold to-black font-roboto">
      <header className="bg-white shadow sticky top-0 z-50 ">
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link to="/">
                <img src={logoImage} alt="Logo" className="h-8" />
              </Link>
            </div>
            <div>
            <div className="flex">
              <Link to="/cart" className="relative text-gray-800 hover:text-gold">
              <ShoppingCartIcon className="h-6 w-6" />
                {cartItemCount > 0 && (
                  <span className="absolute top-3 left-3 inline-flex items-center justify-center w-4 h-4 text-xs font-bold leading-none text-white bg-gold rounded-full">
                    {cartItemCount}
                  </span>
                )}
                </Link>
                <button 
                onClick={handleLogout} 
                className="text-gray-800 hover:text-gold flex items-center ml-4"
              >
                <ArrowRightOnRectangleIcon className="h-6 w-6" />
                
              </button>
            </div>
             
            </div>
          </div>
        </div>
      </header>
      <div id="app" className="flex-grow container mx-auto p-4 md:p-8 font-roboto flex flex-col">
        <div className="bg-white p-4 md:p-6 rounded-lg shadow-md flex-grow h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default HomePage;