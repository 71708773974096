// src/components/TimePicker/TimePicker.tsx
import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { getAvailableTimeSlots } from '../../services/apiService';

// Types for the component props
interface TimePickerProps {
  onTimeChange: (time: string) => void;
  selectedTime: string | null;
  selectedDate: string | null;
  offerId: number;
  groupSize: number;
  venueId: number;
}

// Skeleton loader
const TimeSlotSkeleton: React.FC = () => (
  <div className="grid md:grid-cols-4 grid-cols-3 gap-3">
    {[...Array(12)].map((_, i) => (
      <div key={i} className="animate-pulse flex items-center justify-center h-12 bg-gray-300 rounded-lg"></div>
    ))}
  </div>
);

const TimePicker: React.FC<TimePickerProps> = ({ onTimeChange, selectedTime, selectedDate, offerId, groupSize, venueId }) => {
  const [timeSlots, setTimeSlots] = useState({
    morningSlots: [] as string[],
    afternoonSlots: [] as string[],
    eveningSlots: [] as string[],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTimeSlots = async () => {
      setLoading(true);
      setError('');
      try {

        const response: AvailableSlotsResponse  = await getAvailableTimeSlots(selectedDate || '', offerId, groupSize, venueId);
        const availableSlots = response.availableSlots;
        const morningSlots = availableSlots.filter((slot: string) => parseInt(slot.split(':')[0]) < 12);
        const afternoonSlots = availableSlots.filter((slot: string) => parseInt(slot.split(':')[0]) >= 12 && parseInt(slot.split(':')[0]) < 18);
        const eveningSlots = availableSlots.filter((slot: string) => parseInt(slot.split(':')[0]) >= 18);
        setTimeSlots({ morningSlots, afternoonSlots, eveningSlots });
      } catch (error) {
        setError('Failed to load time slots');
      } finally {
        setLoading(false);
      }
    };

    if (selectedDate && offerId && groupSize && venueId) {
      fetchTimeSlots();
    }
  }, [selectedDate, offerId, groupSize, venueId]);

  const handleTimeChange = (time: string) => {
    onTimeChange(time);
  };

  const { morningSlots, afternoonSlots, eveningSlots } = timeSlots;

  return (
    <div className="space-y-2">
      <div className="mx-auto w-full max-w-md">
        <TabGroup>
          <TabList className="flex gap-4 border-b border-gray-300">
            <Tab className={({ selected }) =>
              selected
                ? 'w-full py-2 px-3 text-sm/6 leading-5 font-semibold text-black shadow border-b-2 border-gold'
                : 'w-full py-2 px-3 text-sm/6 leading-5 font-semibold text-gray-500'}>
              Matin
            </Tab>
            <Tab className={({ selected }) =>
              selected
                ? 'w-full py-2 px-3 text-sm/6 leading-5 font-semibold text-black shadow border-b-2 border-gold'
                : 'w-full py-2 px-3 text-sm/6 leading-5 font-semibold text-gray-500'}>
              Après-midi
            </Tab>
            <Tab className={({ selected }) =>
              selected
                ? 'w-full py-2 px-3 text-sm/6 leading-5 font-semibold text-black shadow border-b-2 border-gold'
                : 'w-full py-2 px-3 text-sm/6 leading-5 font-semibold text-gray-500'}>
              Soir
            </Tab>
          </TabList>
          <TabPanels className="mt-4">
            <TabPanel>
              {loading ? <TimeSlotSkeleton /> : morningSlots.length > 0 ? (
                <RadioGroup value={selectedTime} onChange={handleTimeChange} className="grid md:grid-cols-4 md:gap-4 grid-cols-3 gap-3">
                  {morningSlots.map((slot) => (
                    <RadioGroup.Option key={slot} value={slot} className="group relative flex cursor-pointer rounded-lg bg-gold text-black shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-black data-[checked]:text-gold">
                      {({ checked }) => (
                        <>
                          <div className="flex w-full items-center justify-between">
                            <div className="text-sm/6 py-4 pl-3 pr-1">
                              <p className="font-medium">{slot}</p>
                            </div>
                            <CheckCircleIcon className={`h-6 w-6 mr-1 fill-white ${checked ? 'opacity-100' : 'opacity-0'}`} />
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              ) : (
                <p className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">Aucun créneau disponible le matin</p>
              )}
            </TabPanel>
            <TabPanel>
            {loading ? <TimeSlotSkeleton /> : afternoonSlots.length > 0 ? (
              <RadioGroup value={selectedTime} onChange={handleTimeChange} className="grid md:grid-cols-4 md:gap-4 grid-cols-3 gap-3">
                {afternoonSlots.map((slot) => (
                  <RadioGroup.Option key={slot} value={slot} className="group relative flex cursor-pointer rounded-lg bg-gold text-black shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-black data-[checked]:text-gold">
                    {({ checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="text-sm/6 py-4 pl-3 pr-1">
                            <p className="font-medium">{slot}</p>
                          </div>
                          <CheckCircleIcon className={`h-6 w-6 mr-1 fill-white ${checked ? 'opacity-100' : 'opacity-0'}`} />
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
              ) : (
                <p className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                  Aucun créneau disponible l'après-midi</p>
              )}
            </TabPanel>
            <TabPanel>
            {loading ? <TimeSlotSkeleton /> : eveningSlots.length > 0 ? (
              <RadioGroup value={selectedTime} onChange={handleTimeChange} className="grid md:grid-cols-4 md:gap-4 grid-cols-3 gap-3">
                {eveningSlots.map((slot) => (
                  <RadioGroup.Option key={slot} value={slot} className="group relative flex cursor-pointer rounded-lg bg-gold text-black shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-black data-[checked]:text-gold">
                    {({ checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="text-sm/6 py-4 pl-3 pr-1">
                            <p className="font-medium">{slot}</p>
                          </div>
                          <CheckCircleIcon className={`h-6 w-6 mr-1 fill-white ${checked ? 'opacity-100' : 'opacity-0'}`} />
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
              ) : (
                <p className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                  Aucun créneau disponible le soir
                </p>
              )}
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
};

export default TimePicker;