import React, { useState, useEffect } from 'react';
import { Field, Label, Select } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Offer } from '../../models/offer';


interface GroupSizeSelectorProps {
  onGroupSizeChange: (size: number) => void;
  selectedGroupSize: number;
  onChildrenChange: (count: number) => void;
  selectedChildrenSize: number;
  selectedOffer: Offer;
}

const GroupSizeSelector: React.FC<GroupSizeSelectorProps> = ({
  onGroupSizeChange,
  selectedGroupSize,
  onChildrenChange,
  selectedChildrenSize,
  selectedOffer,
}) => {
  const groupSizes: number[] = [];
  for (let i = 1; i <= 10; i++) {
    groupSizes.push(i);
  }

  const [childrenCount, setChildrenCount] = useState<number>(0);

  const handleGroupSizeChange = (size: number) => {
    onGroupSizeChange(size);
    // Reset children count if group size changes
    if (size <= 2) {
      setChildrenCount(0);
      onChildrenChange(0);
    }
  };

  const handleChildrenChange = (count: number) => {
    setChildrenCount(count);
    onChildrenChange(count);
  };

  useEffect(() => {
    if (selectedOffer.simulator_count < 2) {
      handleGroupSizeChange(1);
    }
  }, [selectedOffer.simulator_count]); // Re-run when `simulator_count` changes

  return (
    <div className="md:px-10">
      <Field>
        <Label className="mb-4 font-semibold text-sm block"> Nombre de pilotes</Label>
        {selectedOffer.simulator_count >= 2 ? (
          <div className="relative">
            <Select
              className={clsx(
                'mt-3 block w-full appearance-none rounded-lg border py-1.5 px-3 text-sm/6',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-gold data-[focus]:border-color-gold',
                '*:text-black'
              )}
              id="groupSize"
              name="groupSize"
              value={selectedGroupSize}
              onChange={(e) => handleGroupSizeChange(parseInt(e.target.value))}
            >
              {groupSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
            <ChevronDownIcon
              className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-gold/60"
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="relative">
            <input
              type="text"
              disabled
              value="1" // Default value or any other placeholder value
              className="bg-gray-100 mt-3 block w-full appearance-none rounded-lg border py-1.5 px-3 text-sm/6"
            />
          </div>
        )}
      </Field>
      {!selectedOffer.is_kid_friendly && (
        <Field>
          <Label className="mb-4 font-semibold text-sm block mt-4">
            Nombre d’enfants de moins 14 ans parmi les pilotes
          </Label>
          <div className="relative">
            <Select
              className={clsx(
                'mt-3 block w-full appearance-none rounded-lg border py-1.5 px-3 text-sm/6',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-gold data-[focus]:border-color-gold',
                '*:text-black'
              )}
              id="childrenCount"
              name="childrenCount"
              value={selectedChildrenSize}
              onChange={(e) => handleChildrenChange(parseInt(e.target.value))}
            >
              <option value={0}>Aucun</option>
              {Array.from({ length: selectedGroupSize }, (_, i) => i + 1).map((count) => (
                <option key={count} value={count}>
                  {count}
                </option>
              ))}
            </Select>
            <ChevronDownIcon
              className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-gold/60"
              aria-hidden="true"
            />
          </div>
        </Field>
      )}
    </div>
  );
};

export default GroupSizeSelector;