import axios from 'axios';
import { Booking } from '../models/booking';

import apiClient from './apiClient'; 

export class BookingService {
  // Use the environment variable for the base API URL
  private static API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
  

  // Fetch bookings by date
  static async getBookingsByDate(date: string, accessToken: string): Promise<Booking[]> {
    
    const response = await apiClient.get(`/bookings`, {
      params: { date },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return response.data;
  }

  // Create a new booking
  static async createBooking(booking: Booking): Promise<Booking> {
    const response = await axios.post(`${this.API_URL}/bookings`, booking);
    return response.data;
  }

  static async getBookingById(bookingId: string, accessToken: string): Promise<Booking> {
    
    const response = await apiClient.get(`/bookings/${bookingId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    return response.data;
  }
}