import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';  // Adjust path based on your structure
import { loginSuccess, loginFailure } from '../store/auth/authSlice';
import LoginForm from '../components/login/LoginForm';
import { login } from '../services/authService';  // Import the service
import { useNavigate } from 'react-router-dom';


const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, error } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');  // Redirect to booking page
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = async (email: string, password: string) => {
    try {
      // Make the API call to login
      const { accessToken, refreshToken } = await login(email, password);

      // Dispatch login success and store tokens
      dispatch(loginSuccess({ email, accessToken, refreshToken }));
      navigate('/'); 
    } catch (error) {
      dispatch(loginFailure('Email ou mot de passe incorrect.'));
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col bg-cover bg-center bg-gradient-to-b from-gold to-black font-roboto">
    <div className="flex w-full h-screen">
      <div className="hidden md:block md:w-1/2 bg-simulator">
      </div>
        {/* If not logged in, show the login form */}
        {!isLoggedIn && <LoginForm onSubmit={handleLogin} errorMessage={error} />}
      </div>
    </div>  
  );
};

export default LoginPage;