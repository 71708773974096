import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBookings } from '../store/booking/bookingListSlice';
import { RootState, AppDispatch  } from '../store';
import Calendar from '../components/calendar/Calendar';
import BookingModal from '../components/calendar/BookingModal';
import { Booking } from '../models/booking';


const BookingCalendarContainer = () => {
  const dispatch: AppDispatch = useDispatch();
  const { bookings, loading, error } = useSelector((state: RootState) => state.bookingList);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Dispatch fetchBookings for a specific date (e.g., today)
    const today = new Date().toISOString().split('T')[0];
    dispatch(fetchBookings(today));
  }, [dispatch]);

  const handleSlotSelect = () => {
    setSelectedBooking(null);  // Open the modal for a new booking
    setIsModalOpen(true);
  };

  const handleEventSelect = (booking: Booking) => {
    setSelectedBooking(booking);  // Open the modal for editing a booking
    setIsModalOpen(true);
  };


  const handleModalSubmit = (newBooking: Partial<Booking>) => {
    if (selectedBooking) {
      console.log('Update booking:', newBooking);
      // Dispatch update booking action here
    } else {
      console.log('Create new booking:', newBooking);
      // Dispatch create booking action here
    }
    setIsModalOpen(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Calendar
        bookings={bookings}
        onSlotSelect={handleSlotSelect}
        onEventSelect={handleEventSelect}
      />
      {isModalOpen && (
        <BookingModal
          booking={selectedBooking || undefined} 
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleModalSubmit}
        />
      )}
    </div>
  );
};

export default BookingCalendarContainer;