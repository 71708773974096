import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPurchase } from '../store/purchase/purchaseSlice';
import ProgressBar from '../components/progressBar/ProgressBar';
import { RootState } from '../store'; // Assuming you have RootState defined

const OrderConfirmationPage: React.FC = () => {
  const purchaseDetails = useSelector((state: RootState) => state.purchase);
  const dispatch = useDispatch();

  useEffect(() => {
    // This cleanup function will run when the component is unmounted
    return () => {
      if (window.location.pathname !== '/order-confirmation') {
        dispatch(clearPurchase());
      }
    };
  }, [dispatch]);


  return (
    <div className="order-page">
      <h1 className="text-2xl font-bold mb-10 text-center">Réservation Sessions Mondial de l'Auto Paris</h1>
      <ProgressBar currentStep ={3}/>
      <div className="order-confirmation-page mx-auto p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6">Confirmation de votre commande</h2>

        <div className="text-left">
          <h2 className="text-2xl font-bold text-gold mb-4">Merci pour votre réservation!</h2>
          <p className="mt-4 text-gray-700">Votre paiement a été traité avec succès.</p>
        </div>

        <h3 className="text-xl font-semibold mt-6">Détails de votre commande</h3>
        {purchaseDetails.cartItems.map((item, index) => (
          <div key={index} className="border-b py-2">
            <div className="flex justify-between">
              <span>Session:</span>
              <span>{item.selectedOffer.name}</span>
            </div>
            <div className="flex justify-between">
              <span>Nombre de pilotes:</span>
              <span>{item.groupSize}</span>
            </div>
            <div className="flex justify-between">
              <span>Total:</span>
              <span> { purchaseDetails.provider  === 'paiement_offert' ? 'Offert' : `${item.finalTotal.toFixed(2)}€` }</span>
            </div>
          </div>
        ))}

        <div className="flex justify-between mt-4 text-lg font-semibold">
          <span>Total payé:</span>
          <span>{ purchaseDetails.provider  === 'paiement_offert' ? 'Offert' :  `${purchaseDetails.finalTotal.toFixed(2)}€`} </span>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmationPage;