import React from 'react';
import {  Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface LightboxProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}

const Lightbox: React.FC<LightboxProps> = ({ isOpen, onClose, children, title }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 md:max-w-2xl">
          <DialogTitle className="flex justify-between items-center bg-gray-100 p-4 rounded-t-lg">
            <h2 className="text-xl font-bold text-gray-700">{title}</h2>
            <button onClick={onClose} className="text-gray-600 hover:text-gray-900">
              <XMarkIcon className="h-6 w-6" />
            </button>
          </DialogTitle>

          <div className="p-6">
            {children}
          </div>
          <div className="bg-gray-100 p-4 flex justify-end space-x-4 rounded-b-lg">
            <button onClick={onClose} className="bg-gold text-white px-4 py-2 rounded-lg hover:bg-black hover:bg-opacity-90">
              Fermer
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default Lightbox;