import React from 'react';
import { Booking } from '../../models/booking';  // Adjust path as needed
import BookingForm from '../bookingForm/BookingForm';
import { BookingModalProps } from '../../models/bookingModalProps';

const BookingModal: React.FC<BookingModalProps> = ({ booking, onClose, onSubmit }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl mb-4">{booking ? 'Edit Booking' : 'New Booking'}</h2>
       {/* <BookingForm booking={booking} onSubmit={onSubmit} />   Render BookingForm */}
        <div className="flex justify-end gap-4 mt-4">
          <button onClick={onClose} className="bg-gray-500 text-white p-2 rounded">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;