import React from 'react';
import logo from './logo.svg';
import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BookingCalendarContainer from './containers/BookingCalendarContainer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import BookingPage from './pages/BookingPage';
import CartPage from './pages/CartPage';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import CheckoutPage from './pages/CheckoutPage';
import CheckoutFailedPage from './pages/CheckoutFailedPage';
import PrivateRoute from './PrivateRoute';
import DashboardPage from './pages/Dashboard';
import BookingDetailsPage from './pages/BookingDetailsPage';
import ParticipantsPage from './pages/ParticipantsPage';
import ChangeTimeBookingPage from './pages/ChangeTimeBookingPage';

function App() {
  return (
      <Router>
            <Routes>
             <Route path="login" element={<LoginPage />}  />
             <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>}>
                  <Route index element={<DashboardPage />} />
                  <Route path="booking" element={<BookingPage />} />
                  <Route path="bookinglist" element={<BookingCalendarContainer />} />
                  <Route path="cart" element={<CartPage />} /> 
                  <Route path="checkout" element={<CheckoutPage />} />   
                  <Route path="order-confirmation" element={<OrderConfirmationPage />} /> 
                  <Route path="checkout-failed" element={<CheckoutFailedPage />} /> 
                  <Route path="booking-details/:bookingId" element={<BookingDetailsPage />} />
                  <Route path="booking/:bookingId/participants" element={<ParticipantsPage />} />
                  <Route path="booking/:bookingId/change-time" element={<ChangeTimeBookingPage />} />
                </Route>
             </Routes>    
      </Router> 
  );
}

export default App;
