import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';

// Define a type for the slice state
interface AuthState {
  isLoggedIn: boolean;
  user: {
    email: string;
  } | null;
  accessToken: string | null;
  refreshToken: string | null;
  error: string | null;
}

// Define the initial state
const initialState: AuthState = {
  isLoggedIn: false,
  user: null,
  accessToken: null,
  refreshToken: null,
  error: null,
};

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const refreshToken = state.auth.refreshToken;

    if (!refreshToken) {
      return rejectWithValue('No refresh token found.');
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/refresh-token`, { refreshToken });
      const { accessToken } = response.data;
      return accessToken;
    } catch (error: any) {
      return rejectWithValue('Failed to refresh token.');
    }
  }
);


// Create a slice for authentication
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Action to log in and store tokens
    loginSuccess: (state, action: PayloadAction<{ email: string, accessToken: string, refreshToken: string }>) => {
      state.isLoggedIn = true;
      state.user = { email: action.payload.email };
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.error = null;
    },
    // Action to log out and clear state
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.error = null;
    },
    // Action for login failure
    loginFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Success case for token refresh
    builder.addCase(refreshAccessToken.fulfilled, (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;  // Update access token on refresh
    });

    // Rejected case for token refresh
    builder.addCase(refreshAccessToken.rejected, (state, action) => {
      // Check if the payload is a SerializedError or string
      if (action.payload && typeof action.payload === 'string') {
        state.error = action.payload;
      } else {
        // Otherwise, use the default error message from action.error
        state.error = action.error?.message || 'Token refresh failed.';
      }
      
      state.isLoggedIn = false;
      state.accessToken = null;
      state.refreshToken = null;  // Optionally log out the user
    });
  }
});

// Export actions
export const { loginSuccess, logout, loginFailure } = authSlice.actions;

// Export the reducer
export default authSlice.reducer;