import React from 'react';
import { Booking } from '../../../models/booking'; // Ensure the correct path to the Booking model
import { formatTimeWithoutZone } from '../../../utils/formatDateTime';

interface BookingModalContentProps {
  booking: Booking;
}

const BookingModalContent: React.FC<BookingModalContentProps> = ({ booking }) => {
  return (
      <>
          <h3 className="text-lg font-bold text-gray-700 mb-4">Informations du client</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
            <div>
              <p className="font-semibold text-gray-600">Nom:</p>
              <p className="text-gray-700">{booking.customer.firstname} {booking.customer.lastname}</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Email:</p>
              <p className="text-gray-700">{booking.customer.email}</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Téléphone:</p>
              <p className="text-gray-700">{booking.customer.tel}</p>
            </div>
          </div>
    
          <h3 className="text-lg font-bold text-gray-700 mb-4">Informations de la réservation</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <p className="font-semibold text-gray-600">Date:</p>
              <p className="text-gray-700">{new Date(booking.startTime).toLocaleDateString("fr-FR")}</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Heure:</p>
              <p className="text-gray-700"> { formatTimeWithoutZone(booking.startTime) }</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Session:</p>
              <p className="text-gray-700"> {booking.offer.name}</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Durée:</p>
              <p className="text-gray-700"> {booking.offer.duration} min</p>
            </div>
            <div>
              <p className="font-semibold text-gray-600">Nombre de pilotes:</p>
              <p className="text-gray-700"> {booking.groupSize} pilote(s)</p>
            </div>
          </div>
       </>
  );
};

export default BookingModalContent;