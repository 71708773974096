// src/components/LoadingOverlay/LoadingOverlay.tsx
import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

// Props for LoadingOverlay
interface LoadingOverlayProps {
  isLoading: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50">
      <ArrowPathIcon className="h-10 w-10 text-black animate-spin" />
    </div>
  );
};

export default LoadingOverlay;