import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { fetchBookings } from "../store/booking/bookingListSlice";
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { getStatusClass, translateStatus } from "../utils/translate";
import { formatDateTime, formatTimeWithoutZone } from "../utils/formatDateTime";
import { Dialog } from '@headlessui/react'
import { Booking } from "../models/booking";
import Lightbox from "../components/lightbox/Lightbox";
import BookingModalContent from "../components/booking/modal/BookingModalContent";


const DashboardPage: React.FC = () => {

  const dispatch: AppDispatch = useDispatch();
  const { bookings, loading, error } = useSelector((state: RootState) => state.bookingList);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);

  

  const [searchTerm, setSearchTerm] = useState(''); 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // Change this to 15 if you want 15 items per page


  const filteredBookings = bookings
    .filter(booking => !booking.is_deleted)
    .filter(booking => 
      `${booking.customer.firstname.toLowerCase()} ${booking.customer.lastname.toLowerCase()}`.includes(searchTerm.toLowerCase()) ||
      booking.customer.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  const totalBookings = filteredBookings.length;
  const completedBookings = filteredBookings.filter(booking => booking.status.toLowerCase() === 'booked').length;
  const pendingBookings = filteredBookings.filter(booking => booking.status.toLowerCase() === 'holding').length;
  const canceledBookings = filteredBookings.filter(booking => booking.status.toLowerCase() === 'cancelled').length;



  // Calculate total pages
  const totalPages = Math.ceil(filteredBookings.length / itemsPerPage);

  // Get the current items for the page
  const currentItems = filteredBookings.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleEdit = (bookingId: number) => {
    navigate(`/booking-details/${bookingId}`);
  };


  const openModal = (booking: Booking) => {
    setSelectedBooking(booking);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBooking(null);
  };

  useEffect(() => {
    // Dispatch fetchBookings for a specific date (e.g., today)
    const today = new Date().toISOString().split('T')[0];
    dispatch(fetchBookings(today));
  }, [dispatch]);


  return (
    <div className="max-w-7xl mx-auto py-6 px-4">
    
    <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
      <div className="relative w-full md:max-w-md">
        <input 
          type="text" 
          placeholder="Rechercher une réservation..."
          value={searchTerm} 
          onChange={handleSearchChange} 
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gold focus:outline-none"
        />
        <button className="absolute right-0 top-0 h-full bg-gold text-white px-4 rounded-r-lg">Rechercher</button>
      </div>
      <Link to="/booking" className="bg-gold text-white px-6 py-2 ml-4 rounded-lg hover:bg-opacity-90 hover:bg-black">
         Ajouter une réservation
      </Link>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-sm font-bold text-gray-500">Total Réservations</h3>
        <p className="text-3xl font-extrabold">{ totalBookings }</p>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-sm font-bold text-gray-500">Réservations Complétées</h3>
        <p className="text-3xl font-extrabold">{ completedBookings }</p>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-sm font-bold text-gray-500">En Attente</h3>
        <p className="text-3xl font-extrabold">{ pendingBookings }</p>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-sm font-bold text-gray-500">Annulées</h3>
        <p className="text-3xl font-extrabold">{ canceledBookings }</p>
      </div>
    </div>

    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Nom Client</th>
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Date</th>
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Session</th>
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Durée</th>
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Nombre de pilotes </th>
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Statut</th>
              <th className="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody>
              {currentItems.map((booking) => (
                <tr key={booking.id}>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {booking.customer.firstname} {booking.customer.lastname}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {new Date(booking.startTime).toLocaleDateString("fr-FR")} {/* Formats the date */}
                    <span className="inline">
                      { formatTimeWithoutZone(booking.startTime) }
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {booking.offer.name}
                  </td>
                  <td className="px-6 py-3 text-sm text-gray-500 text-center">
                    {booking.offer.duration}
                  </td>
                  <td className="px-6 py-3 text-sm text-gray-500  text-center">
                    {booking.groupSize}
                  </td>
                  <td className={`px-6 py-4 text-sm ${booking.status &&  getStatusClass(booking.status)}`}>{booking.status && translateStatus(booking.status)}</td>
                  <td className="px-6 py-4 text-sm">
                  <button onClick={() => openModal(booking)} className="text-blue-500 hover:text-blue-700">
                    <EyeIcon className="h-4 w-4" />
                  </button>
                  <button onClick={() => handleEdit(booking.id)} className="ml-2 text-yellow-500 hover:text-yellow-700">
                    <PencilIcon className="h-4 w-4" />
                  </button>
                  </td>
                </tr>
              ))}
            </tbody>
        </table>
      </div>
    </div>

     {/* Pagination Controls */}
     <div className="flex justify-between items-center mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gold text-white rounded-lg hover:bg-black disabled:bg-gray-100 disabled:text-gray-500"
        >
          Précédent
        </button>
        <span className="text-sm">
          Page {currentPage} sur {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gold text-white rounded-lg hover:bg-black disabled:bg-gray-100 disabled:text-gray-500"
        >
          Suivant
        </button>
      </div>

       {/* Modal for showing booking details */}
       <Lightbox isOpen={isModalOpen} onClose={closeModal} title="Détails de la Réservation">
        {selectedBooking && <BookingModalContent booking={selectedBooking} />}
      </Lightbox>

  </div>
  );
};

export default DashboardPage;