// booked.ts

import { addMinutes, parseISO } from 'date-fns';
import { createBooking } from '../services/apiService';
import { formatDateTime } from './formatDateTime';
import { Offer } from '../models/offer';
import { Customer } from '../models/customer';
import { Booking } from '../models/booking';



// Book an offer
export const bookOffer = async ({
  customers,
  eventDetails,
  bookingdate,
  groupSize,
  childrenSize,
  offer,
}: {
  customers: CustomerDto[];
  eventDetails: EventDetails;
  bookingdate: string;
  groupSize: number;
  childrenSize: number;
  offer: Offer;
}): Promise<Booking> => {
  const startDate = bookingdate;
  const endDate = addMinutes(parseISO(startDate), offer.duration);
  const formattedEndDate = formatDateTime(`${endDate}`);
  const primaryCustomerId = customers[0].id


  const participants = customers.length > 1 ? customers.slice(1).map(customer => ({ customer_id: customer.id })) : null;

  const bookingData: BookingDataDto = {
    event_name: eventDetails.eventName,
    customer_id: primaryCustomerId || 0,
    start_time: startDate,
    end_time: formattedEndDate,
    offer_id: offer.id || 0,
    status: 'Holding',
    venue_id: eventDetails.venueId,
    group_size: groupSize,
    children_size: childrenSize,
    ...(participants && participants.length  && { participants }),
  };

  const data = await createBooking(bookingData);
  return data.booking;
};

// Handle booking errors
export const bookedError = (error: any): string => {
  let message = 'La création de la réservation a échoué.';
  const codesErrors = ['BOOKING_CONFLICT', 'SIMULATOR_UNAVAILABLE'];

  if (error && typeof error === 'object' && 'response' in error) {
    if ('code' in error.response.data && codesErrors.includes(error.response.data.code)) {
      message = error.response.data.message;
    }
  }

  return message;
};