import { configureStore } from '@reduxjs/toolkit';
import bookingListReducer from './booking/bookingListSlice';
import authReducer from './auth/authSlice';
import bookingReducer from './booking/bookingSlice';
import cartReducer from './cart/cartSlice';
import purchaseReducer from './purchase/purchaseSlice';
import offerReducers from './offer/offerSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

// Create a persist configuration for auth slice
const persistConfig = {
  key: 'root',   // Key in localStorage
  storage,       // Type of storage (localStorage)
  whitelist: ['auth', 'cart'],
};


// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  bookingList: bookingListReducer,
  booking: bookingReducer,
  cart: cartReducer,
  purchase: purchaseReducer,
  offers: offerReducers
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types for redux-persist
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;