import React, { useState, useEffect } from 'react';
import DatePicker from '../components/datePicker/DatePicker';
import TimePicker from '../components/timePicker/TimePicker';
import GroupSizeSelector from '../components/groupSizeSelector/GroupSizeSelector';
import Summary from '../components/summary/Summary';

import '../styles/customCalendar.css';
import ErrorMessage from '../components/errorMessage/ErrorMessage';
import ProgressBar from '../components/progressBar/ProgressBar';
import OfferGroup from '../components/offerGroup/OfferGroup';
import CustomerFormsList from '../components/customerFormsList/CustomerFormsList';
import { useDispatch, useSelector } from 'react-redux';
import { updateBookingDetails, resetBooking } from '../store/booking/bookingSlice';
import { addItemToCart, updateCartItemAsBooked } from '../store/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import { calculateFinalTotal } from '../utils/cart';
import { bookOffer } from '../utils/booked';
import { RootState, AppDispatch } from '../store';
import { formatDateTime } from '../utils/formatDateTime';
import { initialOffer, Offer } from '../models/offer';
import BookingForm from '../components/bookingForm/BookingForm';

const eventDetails:EventDetails = {
  eventName: 'MONDIAL AUTO',
  venueId: 1
};

interface BookingPageProps {}

// Component
const BookingPage: React.FC<BookingPageProps> = () => {
  const [slide, setSlide] = useState<number>(1);
  const [date, setDate] = useState<string | null>(null);
  const [formattedDate, setFormattedDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(null);
  const [groupSize, setGroupSize] = useState<number>(1);
  const [childrenSize, setChildrenSize] = useState<number>(0);
  const [customers, setCustomers] = useState<CustomerDto[]>([]);
  const [isBooking, setBooking] = useState<boolean>(false);
  const [selectedOffer, setSelectedOffer] = useState<Offer>(initialOffer);
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  const [errorKey, setErrorKey] = useState<number>(0);
  const dispatch = useDispatch<AppDispatch>();
  const bookingDetails = useSelector((state: RootState) => state.booking);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const navigate = useNavigate();

  // Handlers for events and validation will go here
  const handleNextSlide = () => {
    const errors = validateSelections();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setErrorKey((prevKey) => prevKey + 1);
    } else {
      setSlide(slide + 1);
    }
  };

  const handlePrevSlide = () => {
    setSlide(slide - 1);
  };

  const handleDateChange = (date: string) => {
    setDate(date);
    if (time) {
      const formattedDateTime = formatDateTime(`${date}T${time}`);
      setFormattedDate(formattedDateTime);
    }
    setErrors((prevErrors) => ({ ...prevErrors, date: null }));
  };

  const handleTimeChange = (time: string) => {
    setTime(time);
    if (date) {
      const formattedDateTime = formatDateTime(`${date}T${time}`);
      setFormattedDate(formattedDateTime);
    }
    setErrors((prevErrors) => ({ ...prevErrors, time: null }));
  };

  const handleGroupSizeChange = (size: number) => {
    setGroupSize(size);
    setErrors((prevErrors) => ({ ...prevErrors, groupSize: null }));
  };

  const handleChildrenChange = (size: number) => {
    setChildrenSize(size);
  };

  const handleCustomersChange = (updatedCustomers: CustomerDto[]) => {
    setBooking(updatedCustomers.some((customer) => customer.id !== undefined && customer.id !== null));
    setCustomers(updatedCustomers);
  };

  const handleOfferSelection = (selectedOffer: Offer) => {
    //const { id, name, price, duration, is_kid_friendly, simulator_count } = selectedOffer;
    setSelectedOffer({ ...selectedOffer });
    setErrors((prevErrors) => ({ ...prevErrors, offer: null }));
  };

  const handleBookingSuccess = async (bookingData: any) => {
    const newBookingDetails = {
      ...bookingDetails,
      isBooked: true,
      bookingData,
    };

    dispatch(addItemToCart(newBookingDetails));

    // Check for unbooked items in the cart
    for (let index = 0; index < cartItems.length; index++) {
      const item = cartItems[index];
      if (!item.isBooked) {
        try {
          const formattedDateTime = formatDateTime(`${item.date}T${item.time}`);
          const additionalBookingData = await bookOffer({
            customers: item.customers,
            eventDetails: item.eventDetails,
            bookingdate: formattedDateTime,
            groupSize: item.groupSize,
            childrenSize: item.childrenSize,
            offer: item.selectedOffer,
          });

          // Update the cart item as booked
          dispatch(updateCartItemAsBooked({ index, bookingData: additionalBookingData }));
        } catch (error) {
          console.error('Error booking additional item:', error);
          dispatch(updateCartItemAsBooked({ index, error: 'La réservation a échoué. Veuillez réessayer.' }));
        }
      }
    }

    dispatch(resetBooking());
    setSlide(1);
    navigate('/checkout');
  };

  const handleAddToCart = () => {
    // Add the booking details to the cart
    dispatch(addItemToCart(bookingDetails));

    // Reset the bookingSlice for the next booking
    dispatch(resetBooking());
    setSlide(1);
    navigate('/cart');
  };

  const validateSelections = (): Record<string, string> => {
    const errors: Record<string, string> = {};
    if (!selectedOffer.name) errors.offer = 'Veuillez sélectionner une offre';
    if (!groupSize) errors.groupSize = 'Veuillez sélectionner la taille du groupe';
    if (!date) errors.date = 'Veuillez sélectionner une date';
    if (!time) errors.time = 'Veuillez sélectionner une heure';
    return errors;
  };


  useEffect(() => {
    const { finalTotal, originalTotal } = calculateFinalTotal(selectedOffer, groupSize, childrenSize);
    dispatch(updateBookingDetails({
      ...bookingDetails,
      finalTotal,
      originalTotal,
    }));
  }, [selectedOffer, groupSize, childrenSize, dispatch]);


  useEffect(() => {
    dispatch(updateBookingDetails({
      date,
      time,
      groupSize,
      childrenSize,
      selectedOffer,
      customers,
      eventDetails,
    }));
  }, [date, time, groupSize, childrenSize, selectedOffer, customers, dispatch]);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-10 text-center">Réservation Sessions Mondial de l'Auto Paris</h1>
      <ProgressBar currentStep={slide} />
      <div className="w-full md:px-4 md:pt-4">
        {slide === 1 && (
          <div>
            <div className="w-full">
              <div className="text-center text-2xl font-bold mb-10 text-black">
                Réservez votre Session<br />
                -15% pour les enfants de moins de 14 ans et pour les groupes d’au moins 4 personnes par session
              </div>
              <div className="mx-auto w-full rounded-2xl p-2 md:flex">
                <div className="md:w-3/5 mb-8">
                  <ErrorMessage message={errors.offer} errorKey={errorKey} />
                  <OfferGroup
                    onOfferChange={handleOfferSelection}
                    selectedOffer={selectedOffer}
                  />
                </div>
                <div className="md:w-2/5 mb-8">
                  <ErrorMessage message={errors.groupSize} errorKey={errorKey} />
                  <GroupSizeSelector
                    onGroupSizeChange={handleGroupSizeChange}
                    selectedGroupSize={groupSize}
                    onChildrenChange={handleChildrenChange}
                    selectedChildrenSize={childrenSize}
                    selectedOffer={selectedOffer}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="md:flex">
                <div className="md:w-3/5 md:pr-10">
                  <ErrorMessage message={errors.date} errorKey={errorKey} />
                  <DatePicker onDateChange={handleDateChange} selectedDate={date} />
                </div>
                <div className="md:w-2/5 md:mt-0 mt-8">
                  <ErrorMessage message={errors.time} errorKey={errorKey} />
                  <TimePicker
                    onTimeChange={handleTimeChange}
                    selectedTime={time}
                    selectedDate={date}
                    offerId={selectedOffer.id || 0}
                    groupSize={groupSize}
                    venueId={eventDetails.venueId}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-5">
              <button
                onClick={handleNextSlide}
                className="mt-4 bg-gold text-black py-2 px-4 rounded-md hover:bg-black hover:text-gold"
              >
                ETAPE SUIVANTE
              </button>
            </div>
          </div>
        )}
        {slide === 2 && (
          <div className="w-full">
            <div className="md:flex w-full">
              <div className="md:w-3/5">
                <CustomerFormsList
                  groupSize={groupSize}
                  customers={customers}
                  onCustomersChange={handleCustomersChange}
                  eventDetails={eventDetails}
                />
                {(Array.isArray(customers) && customers.length > 0 && isBooking) && (
                  <div>
                    <div className="flex justify-between mt-4">
                      <button
                        onClick={handleAddToCart}
                        className="mt-4 w-full py-2 px-4 text-black border-black border-2 hover:border-gold hover:bg-gold"
                      >
                        Ajouter au panier
                      </button>
                    </div>
                    <BookingForm
                      customers={customers}
                      eventDetails={eventDetails}
                      bookingdate={formattedDate || ""}
                      groupSize={groupSize}
                      childrenSize={childrenSize}
                      offer={selectedOffer}
                      onBookingSuccess={handleBookingSuccess}
                    />
                  </div>
                )}
              </div>
              <div className="md:w-2/5 md:mt-0 mt-8 px-4">
                <Summary />
              </div>
            </div>
            <div className="flex justify-between mt-5">
              <button
                onClick={handlePrevSlide}
                className="mt-4 bg-gold text-black py-2 px-4 rounded-md hover:bg-black hover:text-gold"
              >
                Retour
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingPage;