export interface Offer {
  id?: number;
  name: string;
  duration: number;  // Duration in minutes
  price: number;
  is_kid_friendly: boolean;
  simulator_count: number;
  description: string;
  additional_info: string;
  createdAt?: Date;
}

export const initialOffer: Offer = {
  id: 0,
  name: '',
  price: 0,
  duration: 0,
  is_kid_friendly: false,
  simulator_count: 0,
  description: '',
  additional_info: ''
}