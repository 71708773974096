export const translateStatus = (status: string) => {
  switch (status.toLowerCase()) {
    case 'booked':
      return 'Réservé';
    case 'holding':
      return 'En attente';
    case 'cancelled':
      return 'Annulée';
    default:
      return status; 
  }
};


export const getStatusClass = (status: string) => {
  switch (status.toLowerCase()) {
    case 'booked':
      return 'text-green-500';
    case 'holding':
      return 'text-yellow-500'; 
    case 'cancelled':
      return 'text-red-500'; 
    default:
      return 'text-gray-500'; 
  }
};